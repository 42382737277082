import generateSlice from '../generateSlice';

const {
  entityAdapter,
  fetchEntities: fetchClients,
  addEntity: addClient,
  updateEntity: updateClient,
  deleteEntity: deleteClient,
  slice
} = generateSlice('clients')

export { fetchClients, addClient, updateClient, deleteClient };

export default slice.reducer;

export const {
  selectAll: selectAllClients,
  selectById: selectClientById,
} = entityAdapter.getSelectors(state => state.clients);
