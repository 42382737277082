import { forwardRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { selectDesksTitle } from '../features/desks/desksSlice';
import { LocaleContext } from '../contexts/LocaleContext';
import { Link } from '../components/Link';

const ListItemLink = forwardRef(({ text, ...other }, ref) => (
  <li>
    <ListItem button component={Link} {...other} ref={ref}>
      <ListItemText primary={text} />
    </ListItem>
  </li>
));

export const Navbar = ({ mobile, onSelected }) => {
  const [locale] = useContext(LocaleContext);
  const desks = useSelector(state => selectDesksTitle(state, locale));

  const handleClick = () => {
    onSelected();
  };

  if (mobile) {
    return (
      <>
        <Divider />
        <List>
          {desks.map(desk => (
            <ListItemLink key={desk.id} to={`deals?desk=${desk.id}`} text={desk.title} onClick={handleClick} />
          ))}
        </List>
        <Divider />
        <List>
          <ListItemLink to="clients" text={<FormattedMessage
            defaultMessage="Clients"
            description="Link" />} onClick={handleClick} />
          <ListItemLink to="resources" text={<FormattedMessage
            defaultMessage="Resources"
            description="Link" />} onClick={handleClick} />
        </List>
      </>
    );
  }

  return (
    <>
      {desks.map(desk => <Button
        color="inherit"
        component={Link}
        to={`deals?desk=${desk.id}`}
        key={desk.id}
      >{desk.title}</Button>)}
      <Button color="inherit" component={Link} to="clients"><FormattedMessage
        defaultMessage="Clients"
        description="Link" /></Button>
      <Button color="inherit" component={Link} to="resources"><FormattedMessage
        defaultMessage="Resources"
        description="Link" /></Button>
    </>
  );
};
