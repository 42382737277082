import { createContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

export const proposedLocales = {
  en: 'English',
  ro: 'Română',
  ru: 'Русский',
};

export const LocaleContext = createContext();

export const loadLocaleData = locale => {
  switch (locale) {
    case 'ro':
      return import('../compiled-locales/ro.json');
    case 'ru':
      return import('../compiled-locales/ru.json');
    default:
      return import('../compiled-locales/en.json');
  }
};

export const LocaleContextProvider = ({ locale: initLocale, messages: initMessages, children }) => {
  const [locale, setLocale] = useState(initLocale);
  const [messages, setMessages] = useState(initMessages);

  useEffect(() => {
    (async () => {
      setMessages(await loadLocaleData(locale));
    })();
  }, [locale]);

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      <IntlProvider locale={locale} defaultLocale="en" messages={messages} onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
