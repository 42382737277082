import React from 'react';
import { parseISO } from 'date-fns';
import { FormattedRelativeTime } from 'react-intl';

export const TimeAgo = ({ timestamp }) => {
  let timeAgo = '';
  if (timestamp) {
    const date = parseISO(timestamp);
    const timePeriod = (date - Date.now()) / 1e3;
    timeAgo = <FormattedRelativeTime value={timePeriod} numeric="auto" updateIntervalInSeconds={1} />;
  }

  return (
    <span title={timestamp}>
      &nbsp; <i>{timeAgo}</i>
    </span>
  );
};
