import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { currentDriver } from '../drivers/driver';

export default function generateSlice(name, { adapterOptions, reducers } = {}) {
  const entityAdapter = createEntityAdapter(adapterOptions);

  const initialState = entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  });

  const fetchEntities = createAsyncThunk(`${name}/${name}Fetch`, () =>
    currentDriver.getItems(name)
  );

  const addEntity = createAsyncThunk(`${name}/${name}Add`, data =>
    currentDriver.addItem(name, data)
  );

  const updateEntity = createAsyncThunk(`${name}/${name}Update`, ({ id, ...data }) =>
    currentDriver.updateItem(name, id, data)
  );

  const reorderEntities = createAsyncThunk(`${name}/${name}Reorder`, ({ ids, from }) =>
    currentDriver.reorderItems(name, ids, from)
  );

  const deleteEntity = createAsyncThunk(`${name}/${name}Delete`, id =>
    currentDriver.deleteItem(name, id)
  );

  const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: {
      [fetchEntities.pending]: (state, action) => {
        state.status = 'loading';
      },
      [fetchEntities.fulfilled]: (state, action) => {
        state.status = 'succeeded';
        entityAdapter.upsertMany(state, action.payload);
        state.error = null;
      },
      [fetchEntities.rejected]: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      },
      [addEntity.fulfilled]: entityAdapter.addOne,
      [updateEntity.fulfilled]: entityAdapter.upsertOne,
      [deleteEntity.fulfilled]: entityAdapter.removeOne,
      [reorderEntities.pending]: (state, action) => {
        state.ids = action.meta.arg.ids;
      },
    },
  });

  return { entityAdapter, fetchEntities, addEntity, updateEntity, reorderEntities, deleteEntity, slice };
}
