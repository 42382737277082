import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import { addClient } from './clientsSlice';

export const AddClientForm = () => {
  const [name, setName] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const intl = useIntl();

  const onNameChanged = e => setName(e.target.value);

  const canSave =
    [name].every(Boolean) && addRequestStatus === 'idle';

  const onSaveClientClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        const resultAction = await dispatch(
          addClient({ name })
        );
        unwrapResult(resultAction);
        setName('');
      } catch (err) {
        console.error('Failed to save the client: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  }

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Add a New Client"
        description="Title"
      /></h2>
      <form>
        <label htmlFor="clientName"><FormattedMessage
          defaultMessage="Client Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="clientName"
          name="clientName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Client Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        <button type="button" onClick={onSaveClientClicked} disabled={!canSave}>
          <FormattedMessage
            defaultMessage="Save Client"
            description="Button"/>
        </button>
      </form>
    </section>
  );
};
