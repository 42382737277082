import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectResourceById } from './resourcesSlice';
import { selectDealsByResource } from '../deals/dealsSlice';
import { Link } from '../../components/Link';

export const ResourcePage = () => {
  const { resourceId } = useParams();
  const resource = useSelector(state => selectResourceById(state, resourceId));

  const dealsForResource = useSelector(state => selectDealsByResource(state, resourceId));

  const dealNames = dealsForResource.map(deal => (
    <li key={deal.id}>
      <Link to={`deals/${deal.id}`}>{deal.name}</Link>
    </li>
  ));

  return (
    <section>
      <h2>{resource.name}</h2>

      <ul>{dealNames}</ul>
    </section>
  );
};
