import { createSelector } from '@reduxjs/toolkit';

import generateSlice from '../generateSlice';

const {
  entityAdapter,
  fetchEntities: fetchResources,
  addEntity: addResource,
  updateEntity: updateResource,
  reorderEntities: reorderResources,
  deleteEntity: deleteResource,
  slice,
} = generateSlice('resources', {
  adapterOptions: {
    sortComparer: (a, b) => a.order - b.order,
  },
});

export { fetchResources, addResource, updateResource, reorderResources, deleteResource };

export default slice.reducer;

export const {
  selectAll: selectAllResources,
  selectById: selectResourceById,
  selectIds: selectResourceIds,
} = entityAdapter.getSelectors(state => state.resources);

export const selectResourcesByDesk = createSelector(
  [selectAllResources, (state, deskId) => deskId],
  (resources, deskId) => deskId ? resources.filter(resource => resource.desk === deskId) : resources
);
