import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import inBrowserLanguage from 'in-browser-language';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { loadLocaleData, LocaleContextProvider, proposedLocales } from './contexts/LocaleContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { fetchPreferences } from './features/auth/authSlice';
import PreviewProvider from './components/preview/PreviewContext';
import fetchData from './utils/fetchData';
import { RouterContextProvider } from './contexts/RouterContext';
import { SignInProvider } from './components/SignInDialog';

(async () => {
  store.subscribe(() => {
    console.log('Store after dispatch: ', store.getState());
  });

  const preferences = await store.dispatch(fetchPreferences());
  console.log('preferences', preferences);
  const locale = preferences.locale || inBrowserLanguage.pick(Object.keys(proposedLocales), 'en');
  const palette = preferences.palette;
  const messages = await loadLocaleData(locale);

  await fetchData(store.dispatch);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <LocaleContextProvider locale={locale} messages={messages}>
          <ThemeContextProvider paletteMode={palette}>
            <PreviewProvider>
              <RouterContextProvider>
                <SignInProvider>
                  <App />
                </SignInProvider>
              </RouterContextProvider>
            </PreviewProvider>
          </ThemeContextProvider>
        </LocaleContextProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
