import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { currentDriver } from '../../drivers/driver';
import { proposedLocales } from '../../contexts/LocaleContext';

export const fetchSettings = createAsyncThunk('company/fetchSettings', async () => {
    const settings = await currentDriver.getSettings();
    if (!settings.locales) {
      settings.locales = Object.keys(proposedLocales);
    }
    return settings;
  }
);

export const setSettings = createAsyncThunk('company/setSettings', data =>
  currentDriver.setSettings(data)
);

const companySlice = createSlice({
  name: 'company',
  initialState: {
    status: 'idle',
    error: null,
    settings: {
      locales: Object.keys(proposedLocales),
    },
  },
  reducers: {},
  extraReducers: {
    [fetchSettings.pending]: state => {
      state.status = 'loading';
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.settings = action.payload;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [setSettings.fulfilled]: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export default companySlice.reducer;
