import { fetchSettings } from '../features/company/companySlice';
import { fetchDesks } from '../features/desks/desksSlice';
import { fetchClients } from '../features/clients/clientsSlice';
import { fetchResources } from '../features/resources/resourcesSlice';
import { fetchDeals } from '../features/deals/dealsSlice';

const fetchData = dispatch => Promise.all([
  dispatch(fetchSettings()),
  dispatch(fetchDesks()),
  dispatch(fetchClients()),
  dispatch(fetchResources()),
  dispatch(fetchDeals()),
]);

export default fetchData;
