import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatISO9075 } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import { addDeal } from './dealsSlice';
import { selectAllClients } from '../clients/clientsSlice';
import { selectAllResources } from '../resources/resourcesSlice';
import { selectAllDesks } from '../desks/desksSlice';

export const AddDealForm = () => {
  const [name, setName] = useState('');
  const [start, setStart] = useState(formatISO9075(new Date()));
  const [clientId, setClientId] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [deskId, setDeskId] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const clients = useSelector(selectAllClients);
  const resources = useSelector(selectAllResources);
  const desks = useSelector(selectAllDesks);
  const intl = useIntl();

  const onNameChanged = e => setName(e.target.value);
  const onStartChanged = e => setStart(e.target.value);
  const onClientChanged = e => setClientId(e.target.value);
  const onResourceChanged = e => setResourceId(e.target.value);
  const onDeskChanged = e => setDeskId(e.target.value);

  const canSave =
    [name, start, clientId, resourceId, deskId].every(Boolean) && addRequestStatus === 'idle';

  const onSaveDealClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        const resultAction = await dispatch(
          addDeal({ name, start, client: clientId, resource: resourceId, desk: deskId })
        );
        unwrapResult(resultAction);
        setName('');
        setStart(formatISO9075(new Date()));
        setClientId('');
        setResourceId('');
        setDeskId('');
      } catch (err) {
        console.error('Failed to save the deal: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  }

  const clientsOptions = clients.map(client => (
    <option key={client.id} value={client.id}>
      {client.name}
    </option>
  ));

  const resourcesOptions = resources.map(resource => (
    <option key={resource.id} value={resource.id}>
      {resource.name}
    </option>
  ));

  const desksOptions = desks.map(desk => (
    <option key={desk.id} value={desk.id}>
      {desk.name}
    </option>
  ));

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Add a New Deal"
        description="Title"
      /></h2>
      <form>
        <label htmlFor="dealName"><FormattedMessage
          defaultMessage="Deal Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="dealName"
          name="dealName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Deal Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        <label htmlFor="dealClient"><FormattedMessage
          defaultMessage="Client"
          description="Field"/>:</label>
        <select id="dealClient" value={clientId} onChange={onClientChanged}>
          <option value=""></option>
          {clientsOptions}
        </select>
        <label htmlFor="dealResource"><FormattedMessage
          defaultMessage="Resource"
          description="Field"/>:</label>
        <select id="dealResource" value={resourceId} onChange={onResourceChanged}>
          <option value=""></option>
          {resourcesOptions}
        </select>
        <label htmlFor="dealDesk"><FormattedMessage
          defaultMessage="Desk"
          description="Field"/>:</label>
        <select id="dealDesk" value={deskId} onChange={onDeskChanged}>
          <option value=""></option>
          {desksOptions}
        </select>
        <label htmlFor="dealStart"><FormattedMessage
          defaultMessage="Start"
          description="Field"/>:</label>
        <textarea
          id="dealStart"
          name="dealStart"
          value={start}
          onChange={onStartChanged}
        />
        <button type="button" onClick={onSaveDealClicked} disabled={!canSave}>
          <FormattedMessage
            defaultMessage="Save Deal"
            description="Button"/>
        </button>
      </form>
    </section>
  );
};
