import { createContext, useReducer } from 'react';

import { previewReducer } from './previewReducer';

export const PreviewContext = createContext();

const PreviewProvider = ({ children }) => {
  const [preview, dispatch] = useReducer(previewReducer, { to: '/' });

  return (
    <PreviewContext.Provider value={{ preview, dispatch }}>
      {children}
    </PreviewContext.Provider>
  );
};

export default PreviewProvider;
