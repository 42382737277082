import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { selectClientById } from './clientsSlice';
import { selectDealsByClient } from '../deals/dealsSlice';
import { Link } from '../../components/Link';
import { invite } from '../../drivers/driver';
import { useSignIn } from '../../components/SignInDialog';
import { selectAuthenticated } from '../auth/authSlice';

export const ClientPage = () => {
  const { clientId } = useParams();
  const client = useSelector(state => selectClientById(state, clientId));
  const dealsForClient = useSelector(state => selectDealsByClient(state, clientId));
  const { setOpen: setSignIn } = useSignIn();
  const isAuthenticated = useSelector(selectAuthenticated);

  const handleSendInvitation = async () => {
    if (!isAuthenticated) {
      setSignIn(true);
    } else {
      await invite({ client: clientId });
    }
  };

  const dealNames = dealsForClient.map(deal => (
    <li key={deal.id}>
      <Link to={`deals/${deal.id}`}>{deal.name}</Link>
    </li>
  ));

  return (
    <section>
      <h2>{client.name}</h2>

      <Button variant="contained" onClick={handleSendInvitation}>
        <FormattedMessage defaultMessage="Send an invitation" description="Button" />
      </Button>

      <ul>{dealNames}</ul>
    </section>
  );
};
