import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { fetchNotifications, selectAllNotifications } from '../features/notifications/notificationsSlice';
import { Link } from '../components/Link';

export const Other = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  const numUnreadNotifications = notifications.filter(n => !n.read).length;

  const fetchNewNotifications = () => dispatch(fetchNotifications());

  let unreadNotificationsBadge;

  if (numUnreadNotifications > 0) {
    unreadNotificationsBadge = (
      <span className="badge">{numUnreadNotifications}</span>
    );
  }

  return (
    <>
      <Button color="inherit" component={Link} to="desks"><FormattedMessage
        defaultMessage="Desks"
        description="Link"/></Button>
      <Button color="inherit" component={Link} to="notifications"><FormattedMessage
        defaultMessage="Notifications"
        description="Link"/> {unreadNotificationsBadge}</Button>
      <button className="button" onClick={fetchNewNotifications}>
        <FormattedMessage
          defaultMessage="Refresh Notifications"
          description="Button"/>
      </button>
    </>
  );
};
