import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { useParams } from 'react-router';
import { Button } from '@material-ui/core';

import { DealClient } from './DealClient';
import { DealResource } from './DealResource';
import { TimeAgo } from './TimeAgo';
import { ReactionButtons } from './ReactionButtons';
import { selectDealById } from './dealsSlice';
import { DealDesk } from './DealDesk';
import { Link } from '../../components/Link';
import { PreviewContext } from '../../components/preview/PreviewContext';
import { invite } from '../../drivers/driver';
import { useSignIn } from '../../components/SignInDialog';
import { selectAuthenticated } from '../auth/authSlice';

export const SingleDealPage = () => {
  const { dealId } = useParams();
  const { dispatch } = useContext(PreviewContext);
  const deal = useSelector(state => selectDealById(state, dealId));
  const { setOpen: setSignIn } = useSignIn();
  const isAuthenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    dispatch({ type: 'SET_DEAL', deal });
  }, [dispatch, deal]);

  const handleSendInvitation = async () => {
    if (!isAuthenticated) {
      setSignIn(true);
    } else {
      await invite({ deal: dealId });
    }
  };

  if (!deal) {
    return (
      <section>
        <h2><FormattedMessage
          defaultMessage="Deal not found!"
          description="Title on single page"/></h2>
      </section>
    );
  }

  return (
    <section>
      <article className="deal">
        <h2>{deal.name}</h2>
        <Button variant="contained" onClick={handleSendInvitation}>
          <FormattedMessage defaultMessage="Send an invitation" description="Button" />
        </Button>
        <div>
          <DealClient clientId={deal.client} />
          <DealResource resourceId={deal.resource} />
          <DealDesk deskId={deal.desk} />
          <TimeAgo timestamp={deal.date} />
        </div>
        <p className="deal-start"><FormattedDate value={parseISO(deal.start)} unit="day"/></p>
        <ReactionButtons deal={deal} />
        <Link to={`/editDeal/${deal.id}`} className="button">
          <FormattedMessage
            defaultMessage="Edit Deal"
            description="Link"/>
        </Link>
      </article>
    </section>
  );
};
