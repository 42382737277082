export const previewReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEALS':
      return {
        ...state,
        to: 'deals',
        deals: action.deals,
      };
    case 'SET_DEAL':
      return {
        ...state,
        to: `deals/${action.deal.id}`,
        deal: action.deal,
      };
    case 'SET_DESK':
      return {
        ...state,
        to: '/',
        locale: action.locale,
        desk: {
          ...state.desk,
          previewTitle: action.previewTitle,
        },
      };
    default:
      return state;
  }
};
