import Dexie from 'dexie';
import { nanoid } from '@reduxjs/toolkit';

const db = new Dexie('recepio');

db.version(1).stores({
  preferences: '',
  settings: '',
  deals: 'id,start',
  clients: 'id',
  resources: 'id',
  desks: 'id',
});

export const lastSessionUsedAuthentication = () => db.preferences.get('auth');

export const setUsedAuthentication = () => db.preferences.put('auth', 'auth');

export const getLocalPreferences = async () => Object.fromEntries((await Promise.all([
  'locale',
  'palette',
  'previewHidden',
].map(async key => [key, await db.preferences.get(key)]))).filter(([, value]) => value));

export const changePreference = async (key, value) => {
  if (value) {
    await db.preferences.put(value, key);
  } else {
    await db.preferences.delete(key);
  }
  return [key, value];
};

export const getSettings = async () => ({
  name: await db.settings.get('name'),
  locales: await db.settings.get('locales'),
});

export const setSettings = async data => {
  await Promise.all(Object.entries(data).map(([key, value]) => {
    return db.settings.put(value, key);
  }));

  return data;
};

export const getItems = name => db[name].toArray();

export const addItem = async (name, data) => {
  data.id = nanoid();
  await db[name].add(data);
  return data;
};

export const updateItem = async (name, id, data) => {
  await db[name].update(id, data);
  return {
    id,
    ...data,
  };
};

export const reorderItems = async (name, ids, from) => {
  await Promise.all(ids.slice(from).map((id, index) => db[name].update(id, { order: from + index })));
};

export const deleteItem = async (name, id) => {
  await db[name].delete(id);
  return id;
};

export const clearAll = async () => {
  await db.preferences.delete('auth');
  await Promise.all([
    db.deals.clear(),
    db.clients.clear(),
    db.resources.clear(),
    db.desks.clear(),
  ]);
};
