import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { Link as RouterLink } from './Link';
import { useBasename } from '../hooks/useBasename';

const breadcrumbNameMap = {
  '/deals': <FormattedMessage
    defaultMessage="Deals"
    description="Link" />,
  '/inbox/important': 'Important',
  '/clients': <FormattedMessage
    defaultMessage="Clients"
    description="Link" />,
  '/resources': <FormattedMessage
    defaultMessage="Resources"
    description="Link" />,
  '/desks': <FormattedMessage
    defaultMessage="Desks"
    description="Link" />,
  '/profile': <FormattedMessage
    defaultMessage="Preferences"
    description="Link" />,
  '/company': <FormattedMessage
    defaultMessage="Company"
    description="Link" />,
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs() {
  const { pathname } = useLocation();
  const basename = useBasename();

  const pathnames = pathname.replace(basename, '').split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
      <LinkRouter color="inherit" to="/">
        <FormattedMessage
          defaultMessage="Dashboard"
          description="Link"/>
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="textPrimary" key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}
