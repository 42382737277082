import React, { useEffect, useRef, useState } from 'react';
import { Dialog as MaterialDialog, withStyles, Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Close as CloseIcon } from '@material-ui/icons';

const stack = [];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(8),
  },
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: theme => theme.spacing(1),
            top: theme => theme.spacing(1),
            color: theme => theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Dialog({ open, title, children, onClose, ...other }) {
  const [isOpen, setIsOpen] = useState(open);
  const modalId = useRef(Date.now());

  useEffect(() => {
    if (!open && window.history.state && window.history.state.reactModalId === modalId.current) {
      window.history.back();
    }
    setIsOpen(open)
    if (open) {
      stack.push(modalId.current);
      window.history.pushState({
        reactModalId: modalId.current,
      }, '');
      const onPopstate = () => {
        if (stack[stack.length - 1] === modalId.current) {
          window.removeEventListener('popstate', onPopstate, false);
          stack.pop();
          onClose();
        }
      }
      window.addEventListener('popstate', onPopstate, false);
      return () => window.removeEventListener('popstate', onPopstate, false);
    }
  }, [open, onClose]);

  const handleClose = () => {
    if (window.history.state.reactModalId === modalId.current) {
      window.history.back();
      return;
    }

    onClose();
  };

  return (
    <MaterialDialog
      open={isOpen}
      scroll="body"
      aria-labelledby={`dialog-title-${modalId}`}
      onClose={handleClose}
      {...other}
    >
      <DialogTitle id={`dialog-title-${modalId}`} onClose={handleClose}>
        {title}
      </DialogTitle>
      {children}
    </MaterialDialog>
  );
}
