import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  currentDriver,
  lastSessionUsedAuthentication,
  setRemoteDriver, copyCompany as driverCopyCompany, setLocalDriver
} from '../../drivers/driver';
import { clearAll, getLocalPreferences } from '../../drivers/local/localDriver';

export const signIn = createAsyncThunk('auth/signIn', async ({ email, password }) => {
  await setRemoteDriver();
  const userCredential = await currentDriver.signIn(email, password);
  return userCredential.user.toJSON();
});

export const signOut = createAsyncThunk('auth/signOut', () => currentDriver.signOut());

export const preferenceChanged = createAsyncThunk('preferences/changePreference',
    ([name, value]) => currentDriver.changePreference(name, value)
);

export const copyCompany = createAsyncThunk('auth/copyCompany', () => driverCopyCompany());

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'idle',
    user: null,
    error: null,
  },
  reducers: {
    authenticated: (state, action) => {
      const user = action.payload;
      console.log('authenticated', user);
      state.status = 'authenticated';
      state.user = user;
      state.error = null;
    },
    signInPending: state => {
      console.log('signInPending');
      state.status = 'pending';
      state.user = null;
      state.error = null;
    },
    signInFulfilled: (state, action) => {
      const user = action.payload;
      console.log('signInFulfilled');
      state.status = 'authenticated';
      state.user = user;
      state.error = null;
    },
    localPreferencesLoaded: (state, action) => {
      const data = action.payload;
      console.log('localPreferencesLoaded', data);
      state.status = 'local';
      state.user = data;
      state.error = null;
    },
    setSignOut: (state, action) => {
      const data = action.payload;
      console.log('setSignOut', data);
      state.status = 'signOut';
      state.user = data;
      state.error = null;
    },
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
      console.log('signIn.pending');
      state.status = 'pending';
      state.user = null;
      state.error = null;
    },
    [signIn.fulfilled]: (state, action) => {
      console.log('signIn.fulfilled');
      const user = action.payload;
      state.status = 'authenticated';
      state.user = user;
      state.error = null;
    },
    [signIn.rejected]: (state, action) => {
      console.log('signIn.rejected');
      state.status = 'failed';
      state.user = null;
      state.error = action.error.message;
    },
    [signOut.pending]: (state, action) => {
      console.log('signOut.pending');
      state.status = 'pending';
      state.user = null;
      state.error = null;
    },
    [signOut.fulfilled]: (state, action) => {
      console.log('signOut.fulfilled');
      state.status = 'signOut';
      state.user = null;
      state.error = null;
    },
    [signOut.rejected]: (state, action) => {
      console.log('signOut.rejected');
      state.status = 'failed';
      state.error = action.error.message;
    },
    [preferenceChanged.pending]: (state, action) => {
      console.log('preferenceChanged.pending');
    },
    [preferenceChanged.fulfilled]: (state, action) => {
      const [name, value] = action.payload;
      console.log('preferenceChanged.fulfilled', name, value);
      state.user[name] = value;
    },
    [preferenceChanged.rejected]: (state, action) => {
      console.log('preferenceChanged.rejected', action);
    },
    [copyCompany.pending]: (state, action) => {
      console.log('copyCompany.pending');
    },
    [copyCompany.fulfilled]: (state, action) => {
      console.log('copyCompany.fulfilled', action);
      const company = action.payload;
      state.user['defaultCompanyId'] = company.id;
    },
    [copyCompany.rejected]: (state, action) => {
      console.log('copyCompany.rejected', action);
    },
  },
})

export const { authenticated, signInPending, localPreferencesLoaded, setSignOut } = authSlice.actions;

/*
export const verifyAuth = () => dispatch => {
  console.log('verifyAuth')
  if (currentDriver.lastSessionUsedAuthentication()) {
    dispatch(authPending())
    currentDriver.onAuthStateChanged(user => {
      console.log('onAuthStateChanged', user)
      dispatch(authStateChanged(user))
    })
  } else {
    console.log('verifyAuth localPreferencesLoaded')
    dispatch(localPreferencesLoaded())
  }
}
*/

export const fetchPreferences = () => dispatch => new Promise(async resolve => {
  const pathname = window.location.pathname;
  const splitPathname = pathname.split('/');
  if (splitPathname[1] === 'c' || await lastSessionUsedAuthentication()) {
    await setRemoteDriver();
    const unregisterAuthObserver = currentDriver.onAuthStateChanged(async user => {
      unregisterAuthObserver();
      if (user) {
        dispatch(authenticated(user));
        resolve(user);
      } else {
        const preferences = await getLocalPreferences();
        resolve(preferences);
        dispatch(setSignOut(preferences));
      }
    });
  } else {
    const preferences = await getLocalPreferences();
    dispatch(localPreferencesLoaded(preferences));
    resolve(preferences);
  }
});

export const startAnotherCompany = () => async dispatch => {
  await clearAll();
  await setLocalDriver();
  dispatch(localPreferencesLoaded());
};

/*
let unregisterAuthObserver;

export const subscribeAuthState = dispatch => {
  console.log('subscribeAuthState', unregisterAuthObserver);
  if (unregisterAuthObserver) {
    return;
  }

  unregisterAuthObserver = currentDriver.onAuthStateChanged(user => {
    console.log('subscribeAuthState onAuthStateChanged', user);
    dispatch(authStateChanged(user));
  });
}

export const unsubscribeAuthState = dispatch => {
  console.log('unsubscribeAuthState', unregisterAuthObserver);
  if (unregisterAuthObserver) {
    unregisterAuthObserver();
  }
}
*/

export default authSlice.reducer;

export const selectAuthenticated = state => state.auth.status === 'authenticated';
export const selectSignOut = state => state.auth.status === 'signOut';
export const selectLocal = state => state.auth.status === 'local';
