import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { FormattedDate, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { DndContext } from '@dnd-kit/core';

import { DealClient } from './DealClient';
import { DealResource } from './DealResource';
import { TimeAgo } from './TimeAgo';
import { ReactionButtons } from './ReactionButtons';
import { selectDealsByClient, selectDealsByDesk } from './dealsSlice';
import { AddDealForm } from './AddDealForm';
import { DealDesk } from './DealDesk';
import { useSearchParams } from '../../hooks/useSearchParams';
import { Link } from '../../components/Link';
import { PreviewContext } from '../../components/preview/PreviewContext';

const DealExcerpt = ({ deal }) => {
  const { dispatch } = useContext(PreviewContext);
  const deals = useSelector(state => selectDealsByClient(state, deal.client));

  const mouseEnterHandler = () => {
    dispatch({ type: 'SET_DEALS', deals: deals });
  };

  return (
    <article className="deal-excerpt" onMouseEnter={mouseEnterHandler}>
      <h3>{deal.name}</h3>
      <div>
        <DealClient clientId={deal.client} />
        <DealResource resourceId={deal.resource} />
        <DealDesk deskId={deal.desk} />
        <TimeAgo timestamp={deal.date} />
      </div>
      <p>
        <FormattedDate value={parseISO(deal.start)} />
        &nbsp; <i><FormattedRelativeTime value={(parseISO(deal.start) - Date.now()) / 1e3} numeric="auto"
        updateIntervalInSeconds={1} /></i>
      </p>
      <ReactionButtons deal={deal} />
      <Link to={`deals/${deal.id}`}>
        <FormattedMessage
          defaultMessage="View Deal"
          description="Link on list page" />
      </Link>
    </article>
  );
};

export const DealsList = () => {
  const { desk } = useSearchParams('desk');
  const deals = useSelector(state => selectDealsByDesk(state, desk));

  const dealStatus = useSelector(state => state.deals.status);
  const error = useSelector(state => state.deals.error);

  let content;

  if (dealStatus === 'loading') {
    content = <div className="loader"><FormattedMessage defaultMessage="Loading..." /></div>;
  } else if (dealStatus === 'succeeded') {
    content = deals.map(deal => (
      <DealExcerpt key={deal.id} deal={deal} />
    ));
  } else if (dealStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section className="deals-list">
      <AddDealForm />
      <h2><FormattedMessage
        defaultMessage="Deals"
        description="Title on list page"/></h2>
      {content}
    </section>
  );
};
