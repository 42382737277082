import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectAuthenticated } from '../features/auth/authSlice';

export function useBasename() {
  const isAuthenticated = useSelector(selectAuthenticated);
  const { pathname } = useLocation();
  const preferences = useSelector(state => state.auth.user);

  return useMemo(() => {
    if (isAuthenticated) {
      const splitPathname = pathname.split('/');
      if (splitPathname[1] === 'c') {
        const companyId = splitPathname[2];
        return `/c/${companyId}`;
      } else {
        return `/c/${preferences.defaultCompanyId}`;
      }
    } else {
      return '';
    }
  }, [isAuthenticated, pathname, preferences]);
}
