import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DialogContent, DialogContentText, useMediaQuery, useTheme } from '@material-ui/core';

import Dialog from './Dialog';
import { selectAuthenticated } from '../features/auth/authSlice';
import SignIn from './SignIn';

const SignInContext = createContext();

const SignInProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();
  const isAuthenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      setOpen(false);
    }
  }, [isAuthenticated]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <SignInContext.Provider value={{ setOpen }}>
      {children}
      {open && <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        title={intl.formatMessage({
          defaultMessage: 'Sign Up or Sign In to use this service',
          description: 'Title',
        })}
      >
        <DialogContent dividers>
          <DialogContentText>
            <FormattedMessage
              defaultMessage="The data is offline and only on your computer. Authenticate and enable for your users to see data online on devices."
            />
          </DialogContentText>
          <SignIn />
        </DialogContent>
      </Dialog>}
    </SignInContext.Provider>
  );
};

const useSignIn = () => {
  const context = useContext(SignInContext);
  if (!context) {
    throw new Error('useSignIn must be used within a SignInProvider');
  }

  return context;
};

export { SignInProvider, useSignIn };
