import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useSearchParams(...parameterNames) {
  const { search } = useLocation();
  const pn = parameterNames.join(','); // join for sake of reducing array of strings to simple, comparable string

  return useMemo(() => { // recalculate only when 'search' or arguments changed
    const searchParams = new URLSearchParams(search);

    return pn.split(',').reduce((accumulator, parameterName) => {
      accumulator[parameterName] = searchParams.get(parameterName);
      return accumulator;
    }, {});
  }, [search, pn]);
}
