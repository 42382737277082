import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectAllDesks } from './desksSlice';
import { AddDeskForm } from './AddDeskForm';
import { Link } from '../../components/Link';

export const DesksList = () => {
  const desks = useSelector(selectAllDesks);

  const renderedDesks = desks.map(desk => (
    <tr key={desk.id}>
      <td><Link to={`/desks/${desk.id}`}>{desk.name}</Link></td>
      <td>{desk.name}</td>
    </tr>
  ));

  return (
    <section>
      <AddDeskForm />
      <h2><FormattedMessage
        defaultMessage="Desks"
        description="Title on list page"/></h2>

      <table><tbody>{renderedDesks}</tbody></table>
    </section>
  );
};
