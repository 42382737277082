import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, Fab } from '@material-ui/core';
import { Check as CheckIcon, SaveAlt as SaveAltIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { drawerWidth } from '../app/drawerWidth';

const useStyles = makeStyles((theme) => ({
  fabWrapper: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiFab-extended svg': {
      marginRight: theme.spacing(1),
    },
  },
  drawerFabWrapper: {
    marginRight: drawerWidth,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

export default function FloatingActionButton({ loading, success }) {
  const classes = useStyles();
  const { previewHidden } = useSelector(state => state.auth.user);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <div className={clsx(classes.fabWrapper, {
      [classes.drawerFabWrapper]: !previewHidden
    })}>
      <Fab
        type="submit"
        variant={!loading && !success ? 'extended' : null}
        aria-label={<FormattedMessage
          defaultMessage="Save"
          description="Button"
        />}
        color="primary"
        className={buttonClassname}
      >
        {success ? <CheckIcon /> : <SaveAltIcon />}
        {!loading && !success && <FormattedMessage
          defaultMessage="Save"
          description="Button"
        />}
      </Fab>
      {loading && <CircularProgress size={68} className={classes.fabProgress} />}
    </div>
  );
}
