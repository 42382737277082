import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import { addDesk } from './desksSlice';

export const AddDeskForm = () => {
  const [name, setName] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const intl = useIntl();

  const onNameChanged = e => setName(e.target.value);

  const canSave =
    [name].every(Boolean) && addRequestStatus === 'idle';

  const onSaveDeskClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        const resultAction = await dispatch(
          addDesk({ name })
        );
        unwrapResult(resultAction);
        setName('');
      } catch (err) {
        console.error('Failed to save the desk: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  }

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Add a New Desk"
        description="Title"
      /></h2>
      <form>
        <label htmlFor="deskName"><FormattedMessage
          defaultMessage="Desk Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="deskName"
          name="deskName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Desk Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        <button type="button" onClick={onSaveDeskClicked} disabled={!canSave}>
          <FormattedMessage
            defaultMessage="Save Desk"
            description="Button"/>
        </button>
      </form>
    </section>
  );
};
