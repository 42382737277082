import { lazy, Suspense, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Box, Link, Typography } from '@material-ui/core';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import SignInForm from './SignInForm';
import { authenticated, copyCompany, startAnotherCompany } from '../features/auth/authSlice';
import { lastSessionUsedAuthentication, setRemoteDriver, setUsedAuthentication } from '../drivers/driver';
import fetchData from '../utils/fetchData';
import { useLocation, useNavigate } from '../contexts/RouterContext';

let FirebaseSignIn;
if (process.env.REACT_APP_DATABASE_DRIVER === 'firebase' &&
  process.env.REACT_APP_DATABASE_DRIVER_FIREBASEUI === 'true') {
  FirebaseSignIn = lazy(() => import('../drivers/firebase/firebaseDriver'));
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      <FormattedMessage
        defaultMessage="Copyright © <a>Recepio</a> {year}."
        values={{
          a: chunks => <Link color="inherit" href="https://recepio.com/">{chunks}</Link>,
          year: new Date().getFullYear(),
        }}
      />
    </Typography>
  );
}

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const handleAuthenticated = useCallback(async user => {
    setRemoteDriver();
    await dispatch(authenticated(user));
    if (!await lastSessionUsedAuthentication()) {
      await setUsedAuthentication();
      if (user.defaultCompanyId) {
        await fetchData(dispatch);
        const companyId = user.defaultCompanyId;
        navigate(`/c/${companyId}`, { replace: true });
      } else {
        const resultAction = await dispatch(copyCompany());
        const companyId = unwrapResult(resultAction).id;
        let url = `/c/${companyId}${pathname}`;
        if (search) {
          url += `?${search}`;
        }
        navigate(url, { replace: true });
      }
    } else {
      if (user.defaultCompanyId) {
        await fetchData(dispatch);
        const companyId = user.defaultCompanyId;
        navigate(`/c/${companyId}`, { replace: true });
      }
    }
  }, [dispatch, navigate, pathname, search]);

  const handleStartAnotherCompany = async () => {
    await dispatch(startAnotherCompany());
    navigate('/', { replace: true });
  }

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      {process.env.REACT_APP_DATABASE_DRIVER === 'firebase' &&
      process.env.REACT_APP_DATABASE_DRIVER_FIREBASEUI === 'true' ?
        <>
          <Suspense fallback={<div>
            <FormattedMessage defaultMessage="Loading..." />
          </div>}>
            <FirebaseSignIn onAuthenticated={handleAuthenticated} />
          </Suspense>
          <Box mt={8}>
            <Link
              component="button"
              variant="body2"
              onClick={handleStartAnotherCompany}
            >
              <FormattedMessage defaultMessage="Start another Company" description="Link" />
            </Link>
          </Box>
        </> :
        <SignInForm />}
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
};
