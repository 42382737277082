import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectAllClients } from './clientsSlice';
import { AddClientForm } from './AddClientForm';
import { Link } from '../../components/Link';

export const ClientsList = () => {
  const clients = useSelector(selectAllClients);

  const renderedClients = clients.map(client => (
    <tr key={client.id}>
      <td><Link to={`/clients/${client.id}`}>{client.name}</Link></td>
      <td>{client.name}</td>
    </tr>
  ));

  return (
    <section>
      <AddClientForm />
      <h2><FormattedMessage
        defaultMessage="Clients"
        description="Title on list page"/></h2>

      <table><tbody>{renderedClients}</tbody></table>
    </section>
  );
};
