import { Box, Stack } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import TabsLayout from '../components/TabsLayout';
import LocaleSwitcher from '../components/LocaleSwitcher';
import ThemeSwitcher from '../components/ThemeSwitcher';

export default function Profile() {
  return (
    <TabsLayout children={[
      ['general', <FormattedMessage
        defaultMessage="General"
        description="Tab"
      />, <Stack spacing={4}>
        <Box>
          <LocaleSwitcher />
        </Box>
        <Box>
          <ThemeSwitcher />
        </Box>
      </Stack>],
    ]} />
  );
}
