import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

import { ThemeContext } from '../contexts/ThemeContext';
import { preferenceChanged } from '../features/auth/authSlice';

export default function ThemeSwitcher() {
  const [paletteMode, setPaletteMode] = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleChange = e => {
    setPaletteMode(e.target.value);
    dispatch(preferenceChanged(['palette', e.target.value]));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <FormattedMessage defaultMessage="Color scheme" description="Label" />
      </FormLabel>
      <RadioGroup
        aria-label={<FormattedMessage defaultMessage="Color scheme" description="Label" />}
        value={paletteMode || ''}
        onChange={handleChange}
      >
        <FormControlLabel value="" control={<Radio />} label={<FormattedMessage defaultMessage="System" description="Option color scheme" />} />
        <FormControlLabel value="light" control={<Radio />} label={<FormattedMessage defaultMessage="Light" description="Option" />} />
        <FormControlLabel value="dark" control={<Radio />} label={<FormattedMessage defaultMessage="Dark" description="Option" />} />
      </RadioGroup>
    </FormControl>
  );
};
