import React, { useState } from 'react';
import {
  Button, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Box, makeStyles
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { signIn } from '../features/auth/authSlice';

const useStyles = makeStyles((theme) => ({
  field: {
    '& .MuiOutlinedInput-input': {
      padding: '16.5px 14px',
    }
  },
}));

export default function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const classes = useStyles();
  const intl = useIntl()
  const status = useSelector(state => state.auth.status)
  const error = useSelector(state => state.auth.error)
  const dispatch = useDispatch()

  const handleEmailChange = e => setEmail(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(signIn({ email, password }));
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        <FormattedMessage
          defaultMessage="Sign in"
          description="Title"
        />
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={intl.formatMessage({
            defaultMessage: 'Email Address',
            description: 'Label',
          })}
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          className={classes.field}
          onChange={handleEmailChange}
        />
        <TextField
          error={status === 'failed'}
          helperText={error}
          margin="normal"
          required
          fullWidth
          name="password"
          label={intl.formatMessage({
            defaultMessage: 'Password',
            description: 'Label',
          })}
          type="password"
          id="password"
          autoComplete="current-password"
          className={classes.field}
          onChange={handlePasswordChange}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={intl.formatMessage({
            defaultMessage: 'Remember me',
            description: 'Label',
          })}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          <FormattedMessage
            defaultMessage="Sign In"
            description="Button"
          />
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              <FormattedMessage
                defaultMessage="Forgot password?"
                description="Link"
              />
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              <FormattedMessage
                defaultMessage="Don't have an account? Sign Up"
                description="Link"
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
