import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useBasename } from '../hooks/useBasename';

export const Link = forwardRef(({ to, ...other }, ref) => {
  const basename = useBasename();

  return <RouterLink ref={ref} to={`${basename}/${to}`} {...other} />
});
