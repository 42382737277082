import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { LocaleContext, proposedLocales } from '../contexts/LocaleContext';
import { preferenceChanged } from '../features/auth/authSlice';

export default function LocaleSwitcher() {
  const [locale, setLocale] = useContext(LocaleContext);
  const dispatch = useDispatch();

  const handleChange = e => {
    setLocale(e.target.value);
    dispatch(preferenceChanged(['locale', e.target.value]));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="locale-select-label">
        <FormattedMessage defaultMessage="Language" description="Label" />
      </InputLabel>
      <Select
        labelId="locale-select-label"
        value={locale}
        onChange={handleChange}
      >
        {Object.keys(proposedLocales).map(locale => <MenuItem value={locale} key={locale}>
          {proposedLocales[locale]}
        </MenuItem>)}
      </Select>
    </FormControl>
  );
};
