import { createSelector } from '@reduxjs/toolkit';
import generateSlice from '../generateSlice';

const {
  entityAdapter,
  fetchEntities: fetchDesks,
  addEntity: addDesk,
  updateEntity: updateDesk,
  deleteEntity: deleteDesk,
  slice,
} = generateSlice('desks');

export { fetchDesks, addDesk, updateDesk, deleteDesk };

export default slice.reducer;

export const {
  selectAll: selectAllDesks,
  selectById: selectDeskById,
} = entityAdapter.getSelectors(state => state.desks);

export const selectDesksTitle = createSelector(
  [selectAllDesks, (state, locale) => locale],
  (desks, locale) => desks.map(desk => ({ ...desk, title: (desk.title && desk.title[locale]) || desk.name }))
);
