import { createSelector } from '@reduxjs/toolkit';
import generateSlice from '../generateSlice';

const {
  entityAdapter,
  fetchEntities: fetchDeals,
  addEntity: addDeal,
  updateEntity: updateDeal,
  deleteEntity: deleteDeal,
  slice,
} = generateSlice('deals', {
  adapterOptions: {
    sortComparer: (a, b) => b.start.localeCompare(a.start),
  },
  reducers: {
    reactionAdded(state, action) {
      const { dealId, reaction } = action.payload;
      const existingDeal = state.entities[dealId];
      if (existingDeal) {
        existingDeal.reactions[reaction]++;
      }
    },
  },
});

export { fetchDeals, addDeal, updateDeal, deleteDeal };

export const { reactionAdded } = slice.actions;

export default slice.reducer;

export const {
  selectAll: selectAllDeals,
  selectById: selectDealById,
  selectIds: selectDealIds,
} = entityAdapter.getSelectors(state => state.deals);

export const selectDealsByClient = createSelector(
  [selectAllDeals, (state, clientId) => clientId],
  (deals, clientId) => deals.filter(deal => deal.client === clientId)
);

export const selectDealsByResource = createSelector(
  [selectAllDeals, (state, resourceId) => resourceId],
  (deals, resourceId) => deals.filter(deal => deal.resource === resourceId)
);

export const selectDealsByDesk = createSelector(
  [selectAllDeals, (state, deskId) => deskId],
  (deals, deskId) => deskId ? deals.filter(deal => deal.desk === deskId) : deals
);
