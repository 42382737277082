import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';

import { addResource, selectResourcesByDesk } from './resourcesSlice';
import { selectAllDesks } from '../desks/desksSlice';

export const AddResourceForm = () => {
  const [name, setName] = useState('');
  const [deskId, setDeskId] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const desks = useSelector(selectAllDesks);
  const resourcesForDesk = useSelector(state => selectResourcesByDesk(state, deskId));
  const intl = useIntl();

  const onNameChanged = e => setName(e.target.value);
  const onDeskChanged = e => setDeskId(e.target.value);

  const canSave =
    [name, deskId].every(Boolean) && addRequestStatus === 'idle';

  const onSaveResourceClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        const resultAction = await dispatch(
          addResource({ order: resourcesForDesk.length, name, desk: deskId })
        );
        unwrapResult(resultAction);
        setName('');
        setDeskId('');
      } catch (err) {
        console.error('Failed to save the resource: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  }

  const desksOptions = desks.map(desk => (
    <option key={desk.id} value={desk.id}>
      {desk.name}
    </option>
  ));

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Add a New Resource"
        description="Title"
      /></h2>
      <form>
        <label htmlFor="resourceName"><FormattedMessage
          defaultMessage="Resource Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="resourceName"
          name="resourceName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Resource Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        <label htmlFor="resourceDesk"><FormattedMessage
          defaultMessage="Desk"
          description="Field"/>:</label>
        <select id="resourceDesk" value={deskId} onChange={onDeskChanged}>
          <option value=""></option>
          {desksOptions}
        </select>
        <button type="button" onClick={onSaveResourceClicked} disabled={!canSave}>
          <FormattedMessage
            defaultMessage="Save Resource"
            description="Button"/>
        </button>
      </form>
    </section>
  );
};
