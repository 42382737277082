import { createContext, useContext, useState } from 'react';

export const RouterContext = createContext();

export const RouterContextProvider = ({ children }) => {
  const [location, setLocation] = useState(window.location);
  const [, navigate] = useState();

  return (
    <RouterContext.Provider value={{ location, setLocation, navigate }}>
      {children}
    </RouterContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error('useLocation must be used within a RouterContextProvider');
  }

  const { location } = context;

  return location;
};

export const useNavigate = () => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error('useNavigate must be used within a RouterContextProvider');
  }

  const { navigate } = context;

  return navigate;
};
