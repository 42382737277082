import { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup, FormHelperText,
  FormLabel,
  Stack,
  TextField
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import TabsLayout from '../components/TabsLayout';
import { setSettings } from '../features/company/companySlice';
import FloatingActionButton from '../components/FloatingActionButton';
import { proposedLocales } from '../contexts/LocaleContext';

const fields = {
  name: ['string'],
  shortName: ['string'],
  description: ['string'],
  backgroundColor: ['color'],
  themeColor: ['color'],
  icon: ['image']
};

export default function Company() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const settings = useSelector(state => state.company.settings);
  const [data, setData] = useState(() => ({ ...settings, name: settings.name || '' }));
  const dispatch = useDispatch();

  const handleInputChange = name => e => {
    console.log('input', name, e.target);
    if (name === 'locales') {
      const value = e.target.checked ?
        [...data[name], e.target.name] :
        data[name].filter(locale => locale !== e.target.name);
      setData({ ...data, [name]: value });
    } else {
      setData({ ...data, [name]: e.target.value });
    }
  };

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault();
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      await dispatch(setSettings(data));

      setSuccess(true);
      setLoading(false);
    }
  };

  return (
    <TabsLayout children={[
      ['general', <FormattedMessage
        defaultMessage="General"
        description="Tab"
      />, <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <Box>
            <TextField
              fullWidth
              label={<FormattedMessage
                defaultMessage="Name"
                description="Label"
              />}
              value={data['name']}
              onChange={handleInputChange('name')}
            />
          </Box>
          <FormControl required error={!data.locales.length} component="fieldset" sx={{ m: 3 }}>
            <FormLabel component="legend"><FormattedMessage
              defaultMessage="Preferred languages"
              description="Label"
            /></FormLabel>
            <FormGroup>
              {Object.keys(proposedLocales).map(locale =>
                <FormControlLabel
                  key={locale}
                  control={
                    <Checkbox
                      checked={data.locales.includes(locale)}
                      onChange={handleInputChange('locales')}
                      name={locale}
                    />
                  }
                  label={proposedLocales[locale]}
                />
              )}
            </FormGroup>
            <FormHelperText>
              <FormattedMessage defaultMessage="Select the languages that will be available to the user" />
            </FormHelperText>
          </FormControl>
        </Stack>
        <FloatingActionButton loading={loading} success={success} />
      </form>],
    ]} />
  );
}
