import { useNavigate } from 'react-router';
import { useBasename } from './useBasename';

export function useCompanyNavigate() {
  const navigate = useNavigate();
  const basename = useBasename();

  return (to, arg) => {
    navigate(typeof to == 'string' ? `${basename}/${to}` : to, arg);
  }
}
