import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth/authSlice';
import companyReducer from '../features/company/companySlice';
import dealsReducer from '../features/deals/dealsSlice';
import clientsReducer from '../features/clients/clientsSlice';
import resourcesReducer from '../features/resources/resourcesSlice';
import desksReducer from '../features/desks/desksSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    deals: dealsReducer,
    clients: clientsReducer,
    resources: resourcesReducer,
    desks: desksReducer,
    notifications: notificationsReducer,
  },
});
