import { useDispatch, useSelector } from 'react-redux';
import { DndContext, closestCenter, KeyboardSensor, useSensor, useSensors, TouchSensor,
  MouseSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable,
  verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormattedMessage } from 'react-intl';

import { reorderResources, selectAllResources, selectResourceIds } from './resourcesSlice';
import { AddResourceForm } from './AddResourceForm';
import { Link } from '../../components/Link';

const ResourceExcerpt = ({ resource }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: resource.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <td><Link to={`/resources/${resource.id}`}>{resource.name}</Link></td>
      <td>{resource.name}</td>
    </tr>
  );
};

export const ResourcesList = () => {
  const resourceIds = useSelector(selectResourceIds);
  const resources = useSelector(selectAllResources);
  const dispatch = useDispatch();
  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  return (
    <section>
      <AddResourceForm />
      <h2><FormattedMessage
        defaultMessage="Resources"
        description="Title on list page"/></h2>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={resourceIds}
          strategy={verticalListSortingStrategy}
        >
          <table>
            <tbody>
              {resources.map(resource => (
                <ResourceExcerpt key={resource.id} resource={resource} />
              ))}
            </tbody>
          </table>
        </SortableContext>
      </DndContext>
    </section>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = resourceIds.indexOf(active.id);
      const newIndex = resourceIds.indexOf(over.id);

      dispatch(reorderResources({
        ids: arrayMove(resourceIds, oldIndex, newIndex),
        from: Math.min(oldIndex, newIndex),
      }));
    }
  }
};
