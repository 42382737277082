import React from 'react';
import { useDispatch } from 'react-redux';

import { reactionAdded } from './dealsSlice';

const reactionEmoji = {
  thumbsUp: '👍',
  hooray: '🎉',
  heart: '❤️',
  rocket: '🚀',
  eyes: '👀',
};

export const ReactionButtons = ({ deal }) => {
  const dispatch = useDispatch();

  const reactionButtons = Object.entries(reactionEmoji).map(([name, emoji]) => {
    return (
      <button
        key={name}
        type="button"
        className="muted-button reaction-button"
        onClick={() =>
          dispatch(reactionAdded({ dealId: deal.id, reaction: name }))
        }
      >
        {emoji} {deal.reactions && deal.reactions[name]}
      </button>
    )
  });

  return <div>{reactionButtons}</div>;
};
