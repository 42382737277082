import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { CssBaseline } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Layout from './app/Layout';
import { DealsList } from './features/deals/DealsList';
import { EditDealForm } from './features/deals/EditDealForm';
import { SingleDealPage } from './features/deals/SingleDealPage';
import { ClientsList } from './features/clients/ClientsList';
import { ClientPage } from './features/clients/ClientPage';
import { ResourcesList } from './features/resources/ResourcesList';
import { ResourcePage } from './features/resources/ResourcePage';
import { DesksList } from './features/desks/DesksList';
import { DeskPage } from './features/desks/DeskPage';
import { NotificationsList } from './features/notifications/NotificationsList';
import { selectAuthenticated, selectLocal, selectSignOut } from './features/auth/authSlice';
import SignInSide from './app/SignInSide';
import { EditDeskForm } from './features/desks/EditDeskForm';
import { useBasename } from './hooks/useBasename';
import RouterBreadcrumbs from './components/RouterBreadcrumbs';
import { Other } from './app/Other';
import Profile from './pages/Profile';
import { RouterContext } from './contexts/RouterContext';
import Company from './pages/Company';

let Preview;
if (process.env.REACT_APP_END_USER_PREVIEW === 'true') {
  Preview = lazy(() => import('./components/Preview'));
}

const AppRoutes = () => {
  const basename = useBasename();
  const location = useLocation();
  const navigate = useNavigate();
  const { setLocation, navigate: contextNavigate } = useContext(RouterContext);

  useEffect(() => {
    setLocation(location);
  }, [setLocation, location]);

  useEffect(() => {
    if (contextNavigate) {
      navigate(contextNavigate);
    }
  }, [navigate, contextNavigate]);

  return (
    <>
      <RouterBreadcrumbs />
      <Routes basename={basename}>
        <Route path="deals" element={<DealsList />} />
        <Route path="deals/:dealId" element={<SingleDealPage />} />
        <Route path="editDeal/:dealId" element={<EditDealForm />} />
        <Route path="clients" element={<ClientsList />} />
        <Route path="clients/:clientId" element={<ClientPage />} />
        <Route path="resources" element={<ResourcesList />} />
        <Route path="resources/:resourceId" element={<ResourcePage />} />
        <Route path="desks" element={<DesksList />} />
        <Route path="desks/:deskId" element={<DeskPage />} />
        <Route path="editDesk/:deskId" element={<EditDeskForm />} />
        <Route path="notifications" element={<NotificationsList />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile/:tab" element={<Profile />} />
        <Route path="company" element={<Company />} />
        <Route path="company/:tab" element={<Company />} />
      </Routes>
      <Other />
    </>
  );
};

export default function App() {
  const isSignOut = useSelector(selectSignOut);
  const isLocal = useSelector(selectLocal);
  const isAuthenticated = useSelector(selectAuthenticated);

  let content;
  if (isSignOut) {
    content = <SignInSide />;
  } else if (isLocal || isAuthenticated) {
    content =
      <Layout
        preview={
          process.env.REACT_APP_END_USER_PREVIEW === 'true' &&
          <Suspense
            fallback={<FormattedMessage defaultMessage="Loading..." />}>
            <Preview />
          </Suspense>
        }>
        <AppRoutes />
      </Layout>
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
}
