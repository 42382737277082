import { useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';


const TabPanel = ({ children, index, value, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: `-${theme.spacing(3)}`,
    marginRight: `-${theme.spacing(3)}`,
  },
  rootVertical: {
    flexGrow: 1,
    display: 'flex',
  },
  tabsHorizontal: {
    width: '100vw',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function TabsLayout({ children }) {
  const classes = useStyles();
  const { tab } = useParams();
  const [value, setValue] = useState(tab || children[0][0]);
  const theme = useTheme();
  const tabsHorizontal = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={clsx(classes.root, {
      [classes.rootVertical]: !tabsHorizontal,
    })}>
      <Tabs
        orientation={tabsHorizontal ? 'horizontal' : 'vertical'}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        scrollButtons
        allowScrollButtonsMobile
        className={tabsHorizontal ? classes.tabsHorizontal : classes.tabsVertical}
      >
        {children.map(([fieldset, legend]) =>
          <Tab
            label={legend}
            value={fieldset}
            {...a11yProps(fieldset)}
            key={fieldset}
            component={Link}
            to={tab ? `../${fieldset}` : fieldset}
          />
        )}
      </Tabs>
      {children.map(([fieldset, , child]) =>
        <TabPanel value={value} index={fieldset} key={fieldset}>
          {child}
        </TabPanel>
      )}
    </div>
  );
}
