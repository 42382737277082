import { createContext, useMemo, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { darkScrollbar, useMediaQuery } from '@material-ui/core';

export const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ paletteMode: initPaletteMode, children }) => {
  const [paletteMode, setPaletteMode] = useState(initPaletteMode);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => {
    const mode = paletteMode || (prefersDarkMode ? 'dark' : 'light');
    return createMuiTheme({
      palette: {
        mode,
      },
      ...(mode === 'dark' && { components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: darkScrollbar(),
          },
        },
      } }),
    });
  }, [paletteMode, prefersDarkMode]);

  return (
    <ThemeContext.Provider value={[paletteMode, setPaletteMode]}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
