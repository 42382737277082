import { sub } from 'date-fns';
import { nanoid } from '@reduxjs/toolkit';

import * as localDriver from './local/localDriver';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const sleepTime = { deals: 1000, clients: 2000, notifications: 4000 };

export const get = async uri => {
  let [path] = uri.split('?');
  path = path.replace('/fakeApi/', '');
  switch (path) {
    case 'notifications':
      await sleep(sleepTime[path]);
      return {
        notifications: [
          {
            id: nanoid(),
            message: 'First Message',
            client: '0',
            date: sub(new Date(), { minutes: 10 }).toISOString(),
            read: false,
            isNew: true,
          },
          {
            id: nanoid(),
            name: 'Second Message',
            client: '2',
            date: sub(new Date(), { minutes: 5 }).toISOString(),
            read: false,
            isNew: true,
          },
        ]
      };

    default:
      throw new Error(`Not found ${path}`);
  }
};

export const post = async (path, data) => {
  switch (path) {
    default:
      throw new Error(`Not found ${path}`);
  }
};

export const lastSessionUsedAuthentication = localDriver.lastSessionUsedAuthentication;
export const setUsedAuthentication = localDriver.setUsedAuthentication;

export let currentDriver = localDriver;

export const copyCompany = async () => {
  const entries = await Promise.all(['desks', 'clients', 'resources', 'deals'].map(async name => {
    return [name, await localDriver.getItems(name)];
  }));
  return currentDriver.createCompany({
    settings: await localDriver.getSettings(),
    ...Object.fromEntries(entries),
  });
};

export const invite = data => currentDriver.invite(data);

export let setRemoteDriver;
switch (process.env.REACT_APP_DATABASE_DRIVER) {
  case 'firebase':
    setRemoteDriver = async () => currentDriver = await import('./firebase/firebaseDriver')
    break;
  case 'mongo':
    setRemoteDriver = async () => currentDriver = await import('./mongo/mongoDriver')
    break;
  default:
    break;
}

export const setLocalDriver = () => {
  currentDriver = localDriver;
};
