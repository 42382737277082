import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';
import clsx from 'clsx';

import { selectAllClients } from '../clients/clientsSlice';
import { allNotificationsRead, selectAllNotifications } from './notificationsSlice';

export const NotificationsList = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  const clients = useSelector(selectAllClients);
  const intl = useIntl();

  useEffect(() => {
    dispatch(allNotificationsRead());
  });

  const renderedNotifications = notifications.map(notification => {
    const date = parseISO(notification.date);
    const client = clients.find(client => client.id === notification.client) || {
      name: intl.formatMessage({
        defaultMessage: 'Unknown Client',
      }),
    };

    const notificationClassname = clsx('notification', {
      new: notification.isNew,
    });

    return (
      <div key={notification.id} className={notificationClassname}>
        <div>
          <b>{client.name}</b> {notification.message}
        </div>
        <div title={notification.date}>
          <i><FormattedRelativeTime value={(date - Date.now()) / 1e3} numeric="auto" updateIntervalInSeconds={1} /></i>
        </div>
      </div>
    );
  });

  return (
    <section className="notificationsList">
      <h2><FormattedMessage
        defaultMessage="Notifications"
        description="Title on list page"/></h2>
      {renderedNotifications}
    </section>
  );
};
