import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { selectDeskById, updateDesk } from './desksSlice';
import { proposedLocales } from '../../contexts/LocaleContext';
import { useCompanyNavigate } from '../../hooks/useCompanyNavigate';
import { PreviewContext } from '../../components/preview/PreviewContext';

export const EditDeskForm = () => {
  const { deskId } = useParams();
  const desk = useSelector(state => selectDeskById(state, deskId));

  const [name, setName] = useState(desk.name);
  const [title, setTitle] = useState(() => Object.fromEntries(Object.keys(proposedLocales).map(locale => [locale, (desk.title && desk.title[locale]) || ''])));
  const [previewTitle, setPreviewTitle] = useState(() => Object.fromEntries(Object.keys(proposedLocales).map(locale => [locale, (desk.previewTitle && desk.previewTitle[locale]) || ''])));

  const dispatch = useDispatch();
  const navigate = useCompanyNavigate();
  const intl = useIntl();
  const { dispatch: previewDispatch } = useContext(PreviewContext);

  const focusInputHandle = (field, locale) => e => {
    if (e.target.value.trim()) {
      previewDispatch({ type: 'SET_DESK', locale, [field]: e.target.value });
    }
  };

  const onNameChanged = e => setName(e.target.value);
  const onTitleChanged = locale => e => setTitle({ ...title, [locale]: e.target.value });
  const onPreviewTitleChanged = locale => e => {
    setPreviewTitle({ ...previewTitle, [locale]: e.target.value });
    previewDispatch({ type: 'SET_DESK', locale, previewTitle: e.target.value });
  };

  const onSaveDeskClicked = () => {
    if (name && title && previewTitle) {
      dispatch(updateDesk({ id: deskId, name, title, previewTitle }));
      navigate(`desks/${deskId}`);
    }
  }

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Edit Desk"
        description="Title on edit page"/></h2>
      <form>
        <label htmlFor="deskName"><FormattedMessage
          defaultMessage="Desk Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="deskName"
          name="deskName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Desk Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        {Object.keys(proposedLocales).map(locale => <div key={locale}>
          <label htmlFor={`deskTitle${locale}`}>{proposedLocales[locale]}:</label>
          <input
            type="text"
            id={`deskTitle${locale}`}
            name="deskTitle"
            value={title[locale]}
            onChange={onTitleChanged(locale)}
          />
        </div>)}
        {Object.keys(proposedLocales).map(locale => <div key={locale}>
          <label htmlFor={`deskPreviewTitle${locale}`}>{proposedLocales[locale]}:</label>
          <input
            type="text"
            id={`deskPreviewTitle${locale}`}
            name="deskPreviewTitle"
            value={previewTitle[locale]}
            onChange={onPreviewTitleChanged(locale)}
            onFocus={focusInputHandle('previewTitle', locale)}
          />
        </div>)}

      </form>
      <button type="button" onClick={onSaveDeskClicked}>
        <FormattedMessage
          defaultMessage="Save Desk"
          description="Button"/>
      </button>
    </section>
  );
};
