import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { deleteDeal, selectDealById, updateDeal } from './dealsSlice';
import { useCompanyNavigate } from '../../hooks/useCompanyNavigate';

export const EditDealForm = () => {
  const { dealId } = useParams();
  const deal = useSelector(state => selectDealById(state, dealId));

  const [name, setName] = useState(deal.name);
  const [start, setStart] = useState(deal.start);

  const dispatch = useDispatch();
  const navigate = useCompanyNavigate();
  const intl = useIntl();

  const onNameChanged = e => setName(e.target.value);
  const onStartChanged = e => setStart(e.target.value);

  const onSaveDealClicked = () => {
    if (name && start) {
      dispatch(updateDeal({ id: dealId, name, start }));
      navigate(`deals/${dealId}`);
    }
  };

  const onDeleteDealClicked = () => {
    dispatch(deleteDeal(dealId));
    navigate(`deals${deal.desk && `?desk=${deal.desk}`}`);
  };

  return (
    <section>
      <h2><FormattedMessage
        defaultMessage="Edit Deal"
        description="Title on edit page"/></h2>
      <form>
        <label htmlFor="dealName"><FormattedMessage
          defaultMessage="Deal Name"
          description="Field"/>:</label>
        <input
          type="text"
          id="dealName"
          name="dealName"
          placeholder={intl.formatMessage({
            defaultMessage: 'Key Deal Name',
            description: 'Placeholder',
          })}
          value={name}
          onChange={onNameChanged}
        />
        <label htmlFor="dealStart"><FormattedMessage
          defaultMessage="Start"
          description="Field"/>:</label>
        <textarea
          id="dealStart"
          name="dealStart"
          value={start}
          onChange={onStartChanged}
        />
      </form>
      <button type="button" onClick={onSaveDealClicked}>
        <FormattedMessage
          defaultMessage="Save Deal"
          description="Button"/>
      </button>
      <button type="button" onClick={onDeleteDealClicked}>
        <FormattedMessage
          defaultMessage="Delete Deal"
          description="Button"/>
      </button>
    </section>
  );
};
