import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectDeskById } from './desksSlice';
import { selectDealsByDesk } from '../deals/dealsSlice';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../components/Link';

export const DeskPage = () => {
  const { deskId } = useParams();
  const desk = useSelector(state => selectDeskById(state, deskId));

  const dealsForDesk = useSelector(state => selectDealsByDesk(state, deskId));

  const dealNames = dealsForDesk.map(deal => (
    <li key={deal.id}>
      <Link to={`deals/${deal.id}`}>{deal.name}</Link>
    </li>
  ));

  return (
    <section>
      <h2>{desk.name}</h2>
      <Link to={`/editDesk/${desk.id}`} className="button">
        <FormattedMessage
          defaultMessage="Edit Desk"
          description="Link"/>
      </Link>

      <ul>{dealNames}</ul>
    </section>
  );
};
